  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/5-2-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/qui/'>Qui sommes nous ?</a></li><li>Equipe</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Le cabinet RV EXPERTISES</h1>
<p>Le cabinet RV EXPERTISES est un cabinet d’expertise indépendant dont les prestations sont principalement axés sur l’expertise d’Assuré et d’expertise de forfait.</p>
<div className='services-details-image'>
    <img src={KDPImagePage} alt="Equipe" />
</div>
<h2>Un cabinet d’expertise indépendant au service de l’assuré</h2>
<p>Créé en 2018, le cabinet RV EXPERTISES est un cabinet d’Experts d’assuré, spécialisé dans l’accompagnement des assurés dans leur problématique de gestion de sinistres.
Créés à l’initiative d’un Expert du bâtiment spécialisé dans les interactions Bâti &amp; Risques Naturels (sécheresse, inondations, tempêtes) et d’un Ingénieur Structure intervenant en sapiteur, le cabinet RV EXPERTISES accompagne les assurés sinistrés dans la défense de leurs intérêts, dans une totale indépendance et impartialité. 
</p>
<h2>Zone d’intervention</h2>
<p>
Le cabinet RV EXPERTISES intervient sur toute la zone Sud-est de la France : Aix-en-Provence, Marseille, Salon, Arles, Brignoles, Cannes, Nice,
</p>
<h2>
Zone d’intervention par département
</h2>
<p>Le cabinet RV EXPERTISES intervient dans les départements suivants :
</p>
<ul>
        <li>Bouches du Rhône,</li>
        <li>Var,</li>
        <li>Alpes Maritimes,</li>
        <li>Vaucluse,</li>
        <li>Alpes de haute Provence,</li>
        <li>Drôme Sud,</li>
        <li>Gard,</li>
        <li>Herault,</li>
</ul>
<p><a href="/contact/">Demandez un devis.</a></p>

                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/qui/references/' className='active'>Equipe</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details